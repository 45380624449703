import React from "react";

import Layout from "@davidway/gatsby-theme-novela/src/components/Layout";
import Section from "@davidway/gatsby-theme-novela/src/components/Section";
import SEO from "@davidway/gatsby-theme-novela/src/components/SEO";
import Paragraph from "@davidway/gatsby-theme-novela/src/components/Paragraph";

function About() {
  return (
    <Layout>
      <SEO />
      <Section>
        <div className="u-mh--xxx-large">
          <Paragraph>Mallory Frye is a Product Designer with over 6 years of experience in UX, UI, and user research. After spending the first chapter of her career designing for large enterprise clients, she has shifted focus toward social impact projects. In her free time, she can be found making vibrant works of art, eating loads of vegetables or striving to meet her Goodreads goal.</Paragraph>

          <Paragraph>
            Whether you've got a project in mind, or just want to say hi, feel free to reach out on <a href="https://www.linkedin.com/in/mallory-frye-a721106a/123">LinkedIn</a>.
          </Paragraph>
        </div>
      </Section>
    </Layout>
  );
}

export default About;

const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
